import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosFlash } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Футбольна школа - Сучасна
			</title>
			<meta name={"description"} content={"Футбольні тренування Сучасна - це те, де прагнучі гравці перетворюються на грізних спортсменів."} />
			<meta property={"og:title"} content={"Головна | Футбольна школа - Сучасна"} />
			<meta property={"og:description"} content={"Футбольні тренування Сучасна - це те, де прагнучі гравці перетворюються на грізних спортсменів."} />
			<meta property={"og:image"} content={"https://ivanoilluminations.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ivanoilluminations.live/img/2817812.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ivanoilluminations.live/img/2817812.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ivanoilluminations.live/img/2817812.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="rgba(0, 0, 0, 0) url(https://ivanoilluminations.live/img/1.jpg) 0% 0% /cover repeat scroll padding-box" quarkly-title="Hero-20">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="flex-start"
				lg-flex-direction="column"
				lg-overflow-x="hidden"
				lg-overflow-y="hidden"
			>
				<Box
				 	margin="30px 150px 20px 150px"
					padding="30px"
					width="50%"
					background="rgba(255, 255, 255, 0.7)"
					display="flex"
					sm-margin="30px 10px 20px 10px"
					align-items="center"
					flex-direction="column"
					border-radius="30px"
				>
					<Text margin="30px 0px 20px 0px" font="normal 700 48px/1.2 --fontFamily-sans" sm-font="normal 700 34px/1.2 --fontFamily-sans" color="--dark">
					"Сучасна" – <br/>Футбольна школа майбутнього
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark">
						Сучасна - це те, де прагнучі гравці перетворюються на грізних спортсменів. Ми спеціалізуємось на навичках, тактиці та фізичній формі, гарантуючи, що кожен стажист набирає перевагу, який їм потрібно досягти на футбольному полі.Наша підготовка є суворою, корисною та орієнтована на розв’язання потенціалу в межах кожного гравця.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-color="--color-dark"
							border-width="2px"
							border-style="solid"
							hover-background="rgba(139, 118, 253, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Почати
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Чому вибрати нас?
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosFlash}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
									Елітний коучинг
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Наші тренери - досвідчені ветерани з пристрастю до виховання наступного покоління футбольного таланту.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosFlash}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
									Індивідуальні навчальні програми
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Ми адаптуємо нашу підготовку відповідно до індивідуальних сильних, слабких сторін та цілей.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosFlash}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
									Сучасні засоби
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Навчіться у найвищих закладах, розроблених для імітації реальних умов гри.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosFlash}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
									Зосередьтеся на командній роботі та лідерстві
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Крім фізичної доблесті, ми підкреслюємо навички будівництва лідерства та міцний командний дух.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://ivanoilluminations.live/img/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://ivanoilluminations.live/img/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					В Футбольна школа - Сучасна, Кожна дриль - це крок до перемоги на полі
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});